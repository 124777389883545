<template>
  <surround-box>
    <div class="menu-container">
      <div class="title">物联网</div>
      <div class="menu-content">
        <div class="menu-title">
          <img class="title-icon" src="/imgs/halo.png" />
          <span>基础信息</span>
        </div>
        <div class="menu-list">
          <div
            @click="menuChange('baseInfo')"
            :class="['menu-item', current == 'baseInfo' ? 'current-item' : '']"
          >
            基础信息
          </div>
          <div
            @click="menuChange('WeatherPrewarning')"
            :class="[
              'menu-item',
              current == 'WeatherPrewarning' ? 'current-item' : '',
            ]"
          >
            气象预报
          </div>
        </div>
        <div class="menu-title">
          <img class="title-icon" src="/imgs/halo.png" />
          <span>数据采集</span>
        </div>
        <div class="menu-list">
          <div
            @click="menuChange('sensor')"
            :class="['menu-item', current == 'sensor' ? 'current-item' : '']"
          >
            传感器
          </div>
        </div>
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox";

export default {
  components: {
    surroundBox,
  },
  props: {
    current: {
      type: String,
      default: "baseInfo",
    },
  },
  methods: {
    menuChange(type) {
      this.$emit("on-change", type);
    },
  },
};
</script>
<style lang='less' scoped>
.menu-container {
  width: 350px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  .title {
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    padding: 8px;
    color: #fff;
    font-size: 18px;
  }
  .menu-content {
    width: 100%;
    .menu-title {
      padding-left: 50px;
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;
      .title-icon {
        position: absolute;
        left: 14px;
        top: 8px;
      }
    }
    .menu-list {
      padding-left: 36px;
      display: flex;
      padding-top: 8px;
      padding-bottom: 8px;
      .menu-item {
        padding: 6px;
        border-radius: 4px;
        margin: 0 6px;
      }
      .current-item {
        border: 1px solid rgba(0, 255, 247, 1);
        padding: 5px;
      }
    }
  }
}
</style>